import { ref, computed } from "vue";
import { useRoute } from 'vue-router'
import { Post } from "@/types/post"
import { PostComment } from "@/types/post_comment";
import { PostRepository } from '@/repositories/PostRepository'
import { useExceptionMessage } from '@/logic/ExceptionMessageLogic'
export const PostLogic = () => {
    const route = useRoute();
    const projectId = computed(() => Number(route.params.project_id))

    const postLoading = ref(false)
    const post = ref<Post>()
    const get = async (post_id: number) => {
        postLoading.value = true;
        const repository: PostRepository = new PostRepository(projectId.value);
        try {
            post.value = await repository.get(post_id);
            postLoading.value = false;
        } catch (e: any) {
            useExceptionMessage(e, "エラーが発生しました。サーバもしくは入力データにエラーがあります。<br>再読み込みしてお試しください");
        }

    };
    const updatePost = async (post_id: number, body: string): Promise<boolean> => {
        // 表示されたものと違う場合は何もしない
        postLoading.value = true;
        const repository: PostRepository = new PostRepository(projectId.value);
        try {
            post.value = await repository.update(post_id, body);
            postLoading.value = false;
            return true;

        } catch (e: any) {
            useExceptionMessage(e, "エラーが発生しました。サーバもしくは入力データにエラーがあります。<br>再読み込みしてお試しください");
            return false;
        }

    };
    const addPost = async (body: string): Promise<boolean> => {
        postLoading.value = true;
        const repository: PostRepository = new PostRepository(projectId.value);
        try {
            await repository.add(body);
            postLoading.value = false
            return true

        } catch (e: any) {
            useExceptionMessage(e, "エラーが発生しました。サーバもしくは入力データにエラーがあります。<br>再読み込みしてお試しください");
            return false
        }

    };
    const deletePost = async (post_id: number): Promise<boolean> => {
        postLoading.value = true;
        const repository: PostRepository = new PostRepository(projectId.value);
        try {
            await repository.delete(post_id)
            postLoading.value = false;
            return true

        } catch (e: any) {
            useExceptionMessage(e, "エラーが発生しました。サーバもしくは入力データにエラーがあります。<br>再読み込みしてお試しください");
            return false
        }

    };
    const iine = async (post_id: number) => {
        postLoading.value = true

        const repository: PostRepository = new PostRepository(projectId.value);
        try {
            const iine_info = await repository.iine(post_id);
            if (post.value?.id == post_id) {
                post.value.iine_count = iine_info.iine_count
                post.value.iine_users = iine_info.iine_users
                post.value.iine = true
            }
            postLoading.value = false;
        } catch (e: any) {
            useExceptionMessage(e, "エラーが発生しました。サーバもしくは入力データにエラーがあります。<br>再読み込みしてお試しください");
        }
    };
    const cancelIine = async (post_id: number) => {
        postLoading.value = true
        const repository: PostRepository = new PostRepository(projectId.value);
        try {
            const iine_info = await repository.cancelIine(post_id);
            if (post.value?.id == post_id) {
                post.value.iine_count = iine_info.iine_count
                post.value.iine_users = iine_info.iine_users
                post.value.iine = false
            }
            postLoading.value = false;

        } catch (e: any) {
            useExceptionMessage(e, "エラーが発生しました。サーバもしくは入力データにエラーがあります。<br>再読み込みしてお試しください");
        }

    }
    const solve = async (post_id: number, solved_flg: boolean) => {
        postLoading.value = true

        const repository: PostRepository = new PostRepository(projectId.value);
        try {
            const solved = await repository.solve(post_id, solved_flg);
            if (post.value?.id == post_id) {
                post.value.solved_flg = solved
            }
            postLoading.value = false;
        } catch (e: any) {
            useExceptionMessage(e, "エラーが発生しました。サーバもしくは入力データにエラーがあります。<br>再読み込みしてお試しください");
        }
    };
    const comment = async (post_id: number, body: string) => {
        postLoading.value = true;
        const repository: PostRepository = new PostRepository(projectId.value);
        try {
            const post_comment: PostComment = await repository.sendComment(post_id, body);
            if (post.value?.id == post_id) {
                post.value.post_comments.unshift(post_comment)
            }
            postLoading.value = false;
        } catch (e: any) {
            useExceptionMessage(e, "エラーが発生しました。サーバもしくは入力データにエラーがあります。<br>再読み込みしてお試しください");

        }

    }
    const deleteComment = async (post_id: number, comment_id: number) => {
        postLoading.value = true;
        const repository: PostRepository = new PostRepository(projectId.value);
        try {
            await repository.deleteComment(post_id, comment_id);
            if (post.value?.id == post_id) {
                for (let i = 0; i < post.value.post_comments.length; i++) {
                    if (post.value.post_comments[i].id == comment_id) {
                        post.value.post_comments.splice(i, 1)
                    }
                }
            }
            postLoading.value = false;

        } catch (e: any) {
            useExceptionMessage(e, "エラーが発生しました。サーバもしくは入力データにエラーがあります。<br>再読み込みしてお試しください");

        }
    }
    const updateComment = async (post_id: number, comment_id: number, body: string): Promise<boolean> => {
        // 表示されたものと違う場合は何もしない
        postLoading.value = true;
        const repository: PostRepository = new PostRepository(projectId.value);
        try {
            const post_comment = await repository.updateComment(post_id, comment_id, body);
            if (post.value) {
                for (let i = 0; i < post.value.post_comments.length; i++) {
                    if (post.value.post_comments[i].id == comment_id) {
                        post.value.post_comments[i] = post_comment
                    }
                }
            }
            postLoading.value = false;
            return true;

        } catch (e: any) {
            useExceptionMessage(e, "エラーが発生しました。サーバもしくは入力データにエラーがあります。<br>再読み込みしてお試しください");
            return false;
        }

    }
    const commentIine = async (post_id: number, comment_id: number) => {
        postLoading.value = true

        const repository: PostRepository = new PostRepository(projectId.value);
        try {
            const iine_count = await repository.commentIine(post_id, comment_id);
            if (post.value?.id == post_id) {
                for (let i = 0; i < post.value.post_comments.length; i++) {
                    if (post.value.post_comments[i].id == comment_id) {
                        post.value.post_comments[i].iine_count = iine_count
                        post.value.post_comments[i].iine = true
                    }
                }
            }
            postLoading.value = false;
        } catch (e: any) {
            useExceptionMessage(e, "エラーが発生しました。サーバもしくは入力データにエラーがあります。<br>再読み込みしてお試しください");
        }
    };
    const commentCancelIine = async (post_id: number, comment_id: number) => {
        postLoading.value = true
        const repository: PostRepository = new PostRepository(projectId.value);
        try {
            const iine_count = await repository.commentCancelIine(post_id, comment_id);
            if (post.value?.id == post_id) {
                for (let i = 0; i < post.value.post_comments.length; i++) {
                    if (post.value.post_comments[i].id == comment_id) {
                        post.value.post_comments[i].iine_count = iine_count
                        post.value.post_comments[i].iine = false
                    }
                }
            }
            postLoading.value = false;

        } catch (e: any) {
            useExceptionMessage(e, "エラーが発生しました。サーバもしくは入力データにエラーがあります。<br>再読み込みしてお試しください");
        }
    }



    const postStateReturn = () => {
        return {
            get,
            addPost,
            updatePost,
            deletePost,
            post,
            iine,
            cancelIine,
            solve,
            comment,
            updateComment,
            deleteComment,
            commentIine,
            commentCancelIine,
            postLoading
        }
    }
    return {
        postStateReturn,
        ...postStateReturn()
    }
}



